/* eslint-disable */
import i18next from 'i18next'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { DATE_FORMAT, REGEX_LAT, REGEX_LNG } from './constants'

export const MAX_TIMEOUT_TIME = 2147483647

const amountWithDecimalsToLocaleString = (amount) => {
  if (amount !== null && amount !== undefined && !isNaN(amount)) {
    return `$ ${Intl.NumberFormat('es-AR').format(amount)}`
  } else if (amount === '0') {
    return '0'
  } else {
    return '0'
  }
}

const convertExpirationToMilliseconds = (unixTime) => {
  let expirationTime = (unixTime - Math.round(+new Date() / 1000)) * 1000
  if (expirationTime > MAX_TIMEOUT_TIME) {
    expirationTime = MAX_TIMEOUT_TIME
  }

  return expirationTime
}

// Funcion que quita los puntos un numero (string)
const plainNumber = (number, toInt = false) => {
  if (number) {
    const value = number.toString().split('.').join('')

    if (toInt) {
      return parseInt(value, 10)
    }

    return value
  }

  return null
}

const reverseNumber = (input) => {
  return [].map
    .call(input, (x) => {
      return x
    })
    .reverse()
    .join('')
}

export const addDots = (value) => {
  if (!value) {
    return 0
  }

  const plain = plainNumber(value.toString())
  const reversed = reverseNumber(plain)
  const reversedWithDots = reversed.match(/.{1,3}/g).join('.')

  return reverseNumber(reversedWithDots)
}

export const formatDate = (date, format = DATE_FORMAT) => {
  const parsedDate = moment(date)
  if (date && parsedDate.isValid()) {
    return moment(date).format(format)
  }

  return null
}

const formatMoney = (value, defaultValue = '-') => {
  if (value) {
    const integerValue = parseInt(value, 10)
    if (integerValue >= 0) {
      return `$ ${addDots(value)}`
    }

    return `- $ ${addDots(integerValue * -1)}`
  }

  return defaultValue
}

const formatVolume = (volume, defaultValue = '-') => {
  if (volume) {
    return `${volume}m3`
  }

  return defaultValue
}

const formatWeight = (weight, defaultValue = '-') => {
  if (weight) {
    return `${weight}kg`
  }

  return defaultValue
}

const getInitials = (name) => {
  const names = name.split(' ')
  let initials = names[0].substring(0, 1).toUpperCase()

  if (names.length > 1) {
    initials += names[1].substring(0, 1).toUpperCase()
  }
  return initials
}

const validateCoordinates = (coordinates) => {
  if (coordinates.lat && coordinates.lng) {
    const validLat = REGEX_LAT.test(coordinates.lat)
    const validLng = REGEX_LNG.test(coordinates.lng)
    return validLat && validLng
  }

  return false
}

const isNumeric = (str) => {
  if (typeof str !== 'string') return false
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str))
}

const formatLastUpdatedAt = (lastUpdatedAt) => {
  if (lastUpdatedAt?.isValid) {
    const { isToday, isYesterday } = lastUpdatedAt

    if (isToday) {
      return i18next.t('todayLastUpdatedAt', { time: lastUpdatedAt.asHourPlainText })
    }
    if (isYesterday) {
      return i18next.t('yesterdayLastUpdatedAt', { time: lastUpdatedAt.asHourPlainText })
    }

    return i18next.t('genericLastUpdatedAt', { dateTime: lastUpdatedAt.asPlainText })
  }

  return null
}

export const insertElementAtIndex = (array, element, index) => {
  if (index < 0 || index > array.length) {
    return array
  }

  array.splice(index, 0, element)

  return array
}

const valueConverter = (value) => value || '-'

const generateUniqueId = () => uuidv4()

const convertToHoursAndMinutes = (minutes) => {
  if (minutes !== null) {
    const parsedMinutes = parseInt(minutes, 10)

    if (parsedMinutes || parsedMinutes === 0) {
      if (parsedMinutes / 60 >= 1) {
        return `${Math.trunc(parsedMinutes / 60)}h ${parsedMinutes % 60}m`
      }

      return `${parsedMinutes}m`
    }
  }

  return null
}

const searchOption = (id, options = []) => {
  let searched = null

  options.forEach((option) => {
    if (option.id === id) {
      searched = option
    }
  })

  return searched
}

const jsonPrettify = (json) => {
  if (typeof json === 'object' && json !== null) {
    const pretty = JSON.stringify(json, undefined, 4)
    return pretty
  }

  try {
    const obj = JSON.parse(json)
    return jsonPrettify(obj)
  } catch (e) {
    return json
  }
}

const dateFormat = (date) => {
  const day = moment(date).format('DD')
  const month = moment(date).format('MM')
  const year = moment(date).format('YYYY')
  const hour = moment(date).format('HH')
  const minutes = moment(date).format('mm')
  const seconds = moment(date).format('ss')

  return `${year}_${month}_${day}_${hour}${minutes}${seconds}`
}

const getOnlyDayFormat = (dateTime) => {
  const dayFormat = new Date(dateTime)
  const d = dayFormat.getDate()
  return d
}

const blobToUrlDownload = (file, fileName) => {
  const fileUrl = window.URL.createObjectURL(file)
  const a = document.createElement('a')
  a.setAttribute('download', fileName)
  a.setAttribute('target', '_blank')
  a.setAttribute('href', fileUrl)
  a.click()
  window.URL.revokeObjectURL(fileUrl)
}
// Function to generate a random hex color
function generateRandomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`
}

// Function to determine if a color is light
function isLightColor(color) {
  // Convert color to RGB values
  const r = parseInt(color.substr(1, 2), 16)
  const g = parseInt(color.substr(3, 2), 16)
  const b = parseInt(color.substr(5, 2), 16)

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Return true if color is light (luminance > 0.5)
  return luminance > 0.5
}

const calculatePolygonCenter = (polygonCoords) => {
  if (!Array.isArray(polygonCoords) || polygonCoords.length === 0) {
    return null
  }

  let x = 0
  let y = 0
  const numPoints = polygonCoords.length

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numPoints; i++) {
    const point = polygonCoords[i]

    // Ensure each point has markerPosition property
    if (!point || !point.markerPosition || !Array.isArray(point.markerPosition)) {
      console.error('Invalid point:', point)
      return null
    }

    const [lat, lng] = point.markerPosition
    x += lat
    y += lng
  }

  const centerX = x / numPoints
  const centerY = y / numPoints

  return [centerX, centerY]
}

export function getColorList(number) {
  const colors = [
    '#0074D9',
    '#FF4136',
    '#FF851B',
    '#FFDC00',
    '#B10DC9',
    '#2ECC40',
    '#39CCCC',
    '#85144b',
    '#3D9970',
    '#FF4136',
    '#F012BE',
    '#FF851B',
    '#FFDC00',
    '#39CCCC',
    '#B10DC9',
    '#0074D9',
    '#85144b',
    '#2ECC40',
    '#3D9970',
    '#FF4136',
    '#F012BE',
    '#2ECC40',
    '#FF851B',
    '#FFDC00',
    '#B10DC9',
    '#39CCCC',
    '#0074D9',
    '#85144b',
    '#F012BE',
    '#3D9970',
  ]

  const result = []

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < number; i++) {
    if (i < colors.length) {
      result.push(colors[i])
    } else {
      // Generate a new random color that isn't white or light gray
      let newColor = generateRandomColor()
      while (isLightColor(newColor)) {
        newColor = generateRandomColor()
      }
      result.push(newColor)
    }
  }

  return result
}

export const colorDifference = (color1, color2) => {
  const r1 = parseInt(color1.substring(1, 3), 16)
  const g1 = parseInt(color1.substring(3, 5), 16)
  const b1 = parseInt(color1.substring(5, 7), 16)
  const r2 = parseInt(color2.substring(1, 3), 16)
  const g2 = parseInt(color2.substring(3, 5), 16)
  const b2 = parseInt(color2.substring(5, 7), 16)
  return Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2)
}

export {
  amountWithDecimalsToLocaleString,
  blobToUrlDownload,
  calculatePolygonCenter,
  convertExpirationToMilliseconds,
  convertToHoursAndMinutes,
  dateFormat,
  formatLastUpdatedAt,
  formatMoney,
  formatVolume,
  formatWeight,
  generateUniqueId,
  getInitials,
  getOnlyDayFormat,
  isNumeric,
  jsonPrettify,
  plainNumber,
  searchOption,
  validateCoordinates,
  valueConverter
}

